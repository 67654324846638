import Builder from "./builder";
import FileDownload from "downloadjs";

class Sender {
  constructor() {
    this._builder = new Builder();
    this._extension = "xml";
  }

  downlaodInvoiceXML(options) {
    return new Promise(async (resolve, reject) => {
      try {
        let companyData = options.companyData;
        let currentInvoice = options.currentInvoice;

        let dynamicXML = {};
        dynamicXML = await this._builder.generateXML(companyData, currentInvoice);

        FileDownload(
          dynamicXML,
          currentInvoice.internalCode + "." + this._extension
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default Sender;
