import Sender from './sender'

class Printer {
  constructor() {
  }
  printInvoiceXML(params) {
    return new Promise((resolve, reject) => {
      const sender = new Sender()
      sender.downlaodInvoiceXML(params).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default new Printer()